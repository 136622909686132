<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <PxHeader />
  <router-link
    to=""
    type="button"
    class="positionButtonOpportunityFarms-listOfFarms absolute z-10 lg:hidden"
  >
    <button-opportunity-farms />
  </router-link>
  <section class="bg-agrogo-green-3 sizeMinAllView">
    <publish-button />
    <!-- CARRUSEL Tipos de fincas -->
    <slider-type-farm :categoryId="categoryId" />
    <!-- SECCION ENCUENTRA LA FINCA IDEAL PARA TI -->
    <div
      class="flex items-center bg-agrogo-yellow-2 h-14 space-x-4 md:h-20 md:justify-center md:space-x-12 xl:justify-around"
    >
      <div
        class="flex space-x-2 md:space-x-4 lg:space-x-8 xl:space-x-16 items-center"
      >
        <ShareWhatsapp
          v-if="userRol == 'Administrador' || userRol == 'SuperAdministrador'"
        />
        <div class="flex">
          <a class="font-nexa text-base text-left pl-8 leading-none md:text-2xl"
            >Encuentra la Finca
            <span class="font-black">perfecta para ti</span></a
          >
        </div>
        <div class="flex pr-12">
          <button
            @click.prevent="() => (isVisible = isVisible ? false : true)"
            type="button"
            :disabled="isDisabled"
            class="btn-primary flex items-center justify-center w-24 h-9 bg-agrogo-green-2 rounded-xl font-nexa text-13px text-agrogo-yellow-1 md:w-32 md:text-base"
          >
            <span>
              {{ isVisible ? "Cerrar" : "Filtrar" }}
            </span>
            <span :class="loadIcon()" class="text-3xs pl-2 md:text-xs"></span>
          </button>
        </div>
      </div>

      <div class="z-10 hidden lg:inline-block">
        <button-opportunity-farms />
      </div>
    </div>
    <!-- Filtros de fincas -->
    <farm-filter
      :isVisible="isVisible"
      :search="search"
      :searchCategoryId="searchCategoryId"
      @applyFilter="applyFilter"
      @deleteAllFilter="deleteAllFilter"
      @fillFilterSuccess="fillFilterSuccess"
      @updateCategoryId="updateCategoryId"
    />
    <!-- CARDSFARMS -->
    <section v-if="isBusy" class="containerGifLoading">
      <img
        src="@/assets/images/loaderagrogo.gif"
        class="sizeGifLoading"
        alt=""
      />
    </section>
    <section v-else>
      <div
        class="px-2 pt-2 pb-6 mt-6 md:px-5 md:pt-2.5 md:pb-5"
        v-if="!isVisible"
      >
        <div class="flex justify-end mb-6 z-0">
          <div class="flex items-center md:pr-4">
            <p
              class="font-nexa font-bold text-xs text-white mr-2 md:text-lg md:mr-4"
            >
              Ordenar:
            </p>
            <v-select
              v-model="selected"
              :options="options"
              :reduce="(option) => option.value"
              class="style-chooser2 bg-white rounded-full style-chooser-farm style-chooser-listoffarms ml-1 sm:ml-0 w-52 md:w-72"
              label="label"
              placeholder="Ordenar"
            >
              <template #no-options>Sin opciones</template>
            </v-select>
          </div>
        </div>
        <div
          v-if="records.length > 0"
          class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 place-content-center place-items-center gap-2 lg:gap-6 pt-2 pb-6 md:pt-2.5 md:pb-5"
        >
          <card-farms
            class="mb-3"
            v-for="(item, index) in records"
            :key="index"
            :item="item"
            @updateFarm="updateFavorite"
          />
        </div>
        <div class="pt-5" v-else>
          <div class="items-center md:items-start md:px-5 md:py-6 md:mx-40">
            <div
              class="flex flex-col px-4 md:pt-3 h-auto w-auto space-y-3 md:space-y-0"
            >
              <div class="flex items-center justify-center mt-7 md:mt-12">
                <img
                  class="w-14 md:w-16"
                  alt=""
                  src="@/assets/images/iconsuccessful.svg"
                />
              </div>
              <div class="md:flex md:justify-center py-8 pb-5">
                <div class="flex items-center px-3 md:px-0">
                  <a
                    class="font-dinpro text-white text-sm text-center lg:text-2xl"
                    >No se encontrarón fincas relacionadas con la busqueda.</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex justify-center items-center w-full mt-6"
          v-if="isLastPage"
        >
          <button
            :disabled="isBusy2"
            @click.prevent="loadFarmsPage"
            class="btn-primary flex items-center justify-center w-32 md:w-56 md:h-12 bg-agrogo-yellow-1 rounded-lg md:rounded-2xl"
          >
            <div class="loader" v-if="isBusy2" />
            <span
              v-else
              class="font-dinpro font-normal text-agrogo-green-2 text-xs md:text-lg"
              >Ver Más</span
            >
          </button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import algoliasearch from "algoliasearch/lite";
import alertMessageMixin from "@/mixins/alertMessageMixin";
import metaMixin from "@/mixins/metaMixin";
import leadCaptaction from "@/mixins/leadCaptaction";
import { mapState } from "vuex";
// components
import PxHeader from "@/components/header/PxHeader";
import SliderTypeFarm from "@/components/slider/SliderTypeFarm";
import CardFarms from "@/views/farm/cards/CardFarms";
import PublishButton from "@/components/publishButton/PublishButton";
import FarmFilter from "./components/FarmFilter";
import buttonOpportunityFarms from "@/components/buttonOpportunityFarms/buttonOpportunityFarms";
import ShareWhatsapp from "./components/ShareWhatsapp.vue";

export default {
  inject: ["provider"],
  name: "ListOfFarms",
  mixins: [alertMessageMixin, leadCaptaction, metaMixin],
  metaInfo() {
    const me = this;
    return me.dataMeta;
  },
  components: {
    SliderTypeFarm,
    CardFarms,
    PxHeader,
    PublishButton,
    FarmFilter,
    buttonOpportunityFarms,
    ShareWhatsapp,
  },
  data() {
    const me = this;
    const algoliaId =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_ALGOLIA_ID_TEST
        : process.env.VUE_APP_ALGOLIA_ID;
    const algoliaPrivateKey =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_PRIVATE_KEY_ALGOLIA_TEST
        : process.env.VUE_APP_PRIVATE_KEY_ALGOLIA;
    return {
      searchClient: algoliasearch(algoliaId, algoliaPrivateKey),
      favoriteRepository: me.provider.favoriteRepository,
      publishFarmRepository: me.provider.publishFarmRepository,
      isVisible: false,
      isBusy: false,
      isBusy2: false,
      records: [],
      favorites: [],
      count: 12, //2 por 12
      isLastPage: false,
      filters: null,
      selected: "alg_properties",
      search: null,
      searchCategoryId: null,
      categoryId: null,
      options: [
        { label: "Ordenar por: Destacados", value: "alg_properties" },
        { label: "Precio: de menor a mayor", value: "alg_properties_asc" },
        { label: "Precio: de mayor a menor", value: "alg_properties_desc" },
      ],
      isDisabled: true,
      userRol: "",
    };
  },
  watch: {
    selected() {
      const me = this;
      if (me.selected !== null) {
        me.sortBy();
      } else {
        me.loadFarms();
      }
    },
    categoryId() {
      const me = this;
      if (me.categoryId) {
        const item = me.category.subcategories.find(
          (el) => el.id == me.categoryId
        );
        if (item?.metadata) {
          me.meta = item?.metadata;
          me.loadDataMeta();
        }
      } else {
        me.getListPropeties();
      }
    },
  },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
    ...mapState("farmTypes", ["category"]),
  },
  methods: {
    loadIcon() {
      const me = this;
      return me.isVisible ? "icon-x" : "icon-filter";
    },
    applyFilter(data, data2) {
      const me = this;
      me.isVisible = false;
      me.filters = "";
      me.search = "";
      me.count = 12; //2 por 12
      let isData = false;
      let isNumeric = false;

      data.forEach((el) => {
        if (el.typeFilter == "areaFrom") {
          el.value = parseFloat(
            data2.areaFrom.split(".").join("").split(",").join(".")
          );
        } else if (el.typeFilter == "areaUntil") {
          el.value = parseFloat(
            data2.areaUntil.split(".").join("").split(",").join(".")
          );
        } else if (el.typeFilter == "priceHectareFrom") {
          el.value = Number(
            data2.priceHectareFrom.split("$")[1].split(".").join("")
          );
        } else if (el.typeFilter == "priceHectareUntil") {
          el.value = Number(
            data2.priceHectareUntil.split("$")[1].split(".").join("")
          );
        } else if (el.typeFilter == "priceTotalFrom") {
          el.value = Number(
            data2.priceTotalFrom.split("$")[1].split(".").join("")
          );
        } else if (el.typeFilter == "priceTotalUntil") {
          el.value = Number(
            data2.priceTotalUntil.split("$")[1].split(".").join("")
          );
        } else if (el.typeFilter == "barterPercentage") {
          el.value = parseFloat(data2.barterPercentage);
        } else if (el.typeFilter == "heightFrom") {
          el.value = Number(data2.heightFrom.split(".").join(""));
        } else if (el.typeFilter == "heightUntil") {
          el.value = Number(data2.heightUntil.split(".").join(""));
        } else if (el.typeFilter == "distanceFrom") {
          el.value = Number(data2.distanceFrom.split(".").join(""));
        } else if (el.typeFilter == "distanceUntil") {
          el.value = Number(data2.distanceUntil.split(".").join(""));
        }
      });

      // Filtro tipos de finca
      const typeFarms = data.filter((el) => el.typeFilter == "filterTypeFarm");

      if (typeFarms.length > 1) me.categoryId = null;
      if (typeFarms.length > 0) {
        me.filters = "(";
        typeFarms.forEach((el, index) => {
          me.filters =
            me.filters +
            `alg_categories.name_subcategory: "${el.text}" ${
              typeFarms.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }
      // Filtro departamentos
      const departaments = data.filter(
        (el) => el.typeFilter == "filtroDepartamento"
      );

      if (departaments.length > 0) {
        departaments.forEach((el, index) => {
          let conditional = "";
          isData && index == 0
            ? (conditional = "AND (")
            : (conditional = index == 0 ? "(" : "");
          me.filters =
            me.filters +
            ` ${conditional} department.name: "${el.text}" ${
              departaments.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }

      // Filtro municipios
      const municipalities = data.filter(
        (el) => el.typeFilter == "filterMunicipie"
      );

      if (municipalities.length > 0) {
        municipalities.forEach((el, index) => {
          let conditional = "";
          isData && index == 0
            ? (conditional = "AND (")
            : (conditional = index == 0 ? "(" : "");
          me.filters =
            me.filters +
            ` ${conditional} municipality.name: "${el.text} "${
              municipalities.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }

      // Filtro por area

      const areaFrom = data.find((el) => el.typeFilter == "areaFrom");

      const areaUntil = data.find((el) => el.typeFilter == "areaUntil");

      if (areaFrom) {
        let conditional = "";
        isData ? (conditional = "AND (") : (conditional = "(");
        me.filters = me.filters + `${conditional} area >= ${areaFrom.value})`;
        isData = false;
        isNumeric = true;
      }

      if (areaUntil) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters = me.filters + ` ${conditional} area <= ${areaUntil.value})`;
        isData = false;
        isNumeric = true;
      }

      // Filtro precio hectarea desde - hasta

      const priceHectareFrom = data.find(
        (el) => el.typeFilter == "priceHectareFrom"
      );

      const priceHectareUntil = data.find(
        (el) => el.typeFilter == "priceHectareUntil"
      );

      if (priceHectareFrom) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters +
          ` ${conditional} precio_hectarea >= ${priceHectareFrom.value})`;
        isData = false;
        isNumeric = true;
      }

      if (priceHectareUntil) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters +
          ` ${conditional} precio_hectarea <= ${priceHectareUntil.value})`;
        isData = false;
        isNumeric = true;
      }

      // Filtro precio total desde - hasta

      const priceTotalFrom = data.find(
        (el) => el.typeFilter == "priceTotalFrom"
      );

      const priceTotalUntil = data.find(
        (el) => el.typeFilter == "priceTotalUntil"
      );

      if (priceTotalFrom) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters + ` ${conditional} price >= ${priceTotalFrom.value})`;
        isData = false;
        isNumeric = true;
      }

      if (priceTotalUntil) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters + ` ${conditional} price <= ${priceTotalUntil.value})`;
        isData = false;
        isNumeric = true;
      }

      // Filtro porcentaje de permuta
      const barterPercentage = data.find(
        (el) => el.typeFilter == "barterPercentage"
      );

      if (barterPercentage) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters +
          ` ${conditional} alg_attributes.name_attribute: "% de permuta" AND alg_attributes.value <= ${barterPercentage.value})`;
        isData = false;
        isNumeric = true;
      }
      // Filtro uso de suelo
      const landUse = data.filter((el) => el.typeFilter == "landUse");

      if (landUse.length > 0) {
        landUse.forEach((el, index) => {
          let conditional = "";
          if (isData && index == 0) {
            conditional = "AND (";
          } else if (isNumeric && index == 0) {
            conditional = "AND (";
          } else {
            conditional = index == 0 ? "(" : "";
          }
          me.filters =
            me.filters +
            ` ${conditional} alg_attributes.name_subattribute: "${el.text} "${
              landUse.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }
      // Filtro topografia
      const typography = data.filter((el) => el.typeFilter == "typography");

      if (typography.length > 0) {
        typography.forEach((el, index) => {
          let conditional = "";
          if (isData && index == 0) {
            conditional = "AND (";
          } else if (isNumeric && index == 0) {
            conditional = "AND (";
          } else {
            conditional = index == 0 ? "(" : "";
          }
          me.filters =
            me.filters +
            ` ${conditional} alg_attributes.name_subattribute: "${el.text} "${
              typography.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }
      // Filtro tipos de suelos
      const soilTypes = data.filter((el) => el.typeFilter == "soilTypes");

      if (soilTypes.length > 0) {
        soilTypes.forEach((el, index) => {
          let conditional = "";
          if (isData && index == 0) {
            conditional = "AND (";
          } else if (isNumeric && index == 0) {
            conditional = "AND (";
          } else {
            conditional = index == 0 ? "(" : "";
          }
          me.filters =
            me.filters +
            ` ${conditional} alg_attributes.name_subattribute: "${el.text} "${
              soilTypes.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }
      // Filtro aguas
      const water = data.filter((el) => el.typeFilter == "water");

      if (water.length > 0) {
        water.forEach((el, index) => {
          let conditional = "";
          if (isData && index == 0) {
            conditional = "AND (";
          } else if (isNumeric && index == 0) {
            conditional = "AND (";
          } else {
            conditional = index == 0 ? "(" : "";
          }
          me.filters =
            me.filters +
            ` ${conditional} alg_attributes.name_subattribute: "${el.text} "${
              water.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }
      // Filtro de pastos
      const filterGrasses = data.filter(
        (el) => el.typeFilter == "filterGrasses"
      );

      if (filterGrasses.length > 0) {
        filterGrasses.forEach((el, index) => {
          let conditional = "";
          if (isData && index == 0) {
            conditional = "AND (";
          } else if (isNumeric && index == 0) {
            conditional = "AND (";
          } else {
            conditional = index == 0 ? "(" : "";
          }
          me.filters =
            me.filters +
            ` ${conditional} alg_attributes.name_subattribute: "${el.text} "${
              filterGrasses.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }
      // Filtro de cultivos
      const filterCrops = data.filter((el) => el.typeFilter == "filterCrops");

      if (filterCrops.length > 0) {
        filterCrops.forEach((el, index) => {
          let conditional = "";
          if (isData && index == 0) {
            conditional = "AND (";
          } else if (isNumeric && index == 0) {
            conditional = "AND (";
          } else {
            conditional = index == 0 ? "(" : "";
          }
          me.filters =
            me.filters +
            ` ${conditional} alg_attributes.name_subattribute: "${el.text} "${
              filterCrops.length - 1 !== index ? "OR " : ")"
            }`;
        });
        isData = true;
      }

      // Filtro de altura
      const heightFrom = data.find((el) => el.typeFilter == "heightFrom");

      const heightUntil = data.find((el) => el.typeFilter == "heightUntil");

      if (heightFrom) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters + ` ${conditional} altura_min >= ${heightFrom.value})`;
        isData = false;
        isNumeric = true;
      }

      if (heightUntil) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters + ` ${conditional} altura_max <= ${heightUntil.value})`;
        isData = false;
        isNumeric = true;
      }

      // Filtro de distacia pavimentada
      const distanceFrom = data.find((el) => el.typeFilter == "distanceFrom");

      const distanceUntil = data.find((el) => el.typeFilter == "distanceUntil");

      if (distanceFrom) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters +
          ` ${conditional} distancia_via_pavimentada >= ${distanceFrom.value})`;
        isData = false;
        isNumeric = true;
      }

      if (distanceUntil) {
        let conditional = "";
        isData || isNumeric ? (conditional = "AND (") : (conditional = "(");
        me.filters =
          me.filters +
          ` ${conditional} distancia_via_pavimentada <= ${distanceUntil.value})`;
        isData = false;
        isNumeric = true;
      }

      console.log(me.filters);
      window.scroll(0, 0);
      const index = me.searchClient.initIndex("alg_properties");
      index
        .search("", {
          filters: me.filters,
          hitsPerPage: 12,
        })
        .then(
          ({ hits }) => {
            me.records = hits;
            me.isBusy = false;
            me.isDisabled = false;
            me.verifyPaginate();
            me.updateFavorite();
          },
          () => {
            // console.log(error);
            me.isBusy = false;
            me.isDisabled = false;
          }
        );
    },
    loadFarms() {
      const me = this;
      window.scroll(0, 0);
      me.isVisible = me.isVisible ? true : false;
      me.isBusy = me.isVisible ? false : true;
      me.records = [];
      me.filters = null;
      me.selected = "alg_properties";
      const index = me.searchClient.initIndex(me.selected);
      //2 por 12
      index
        .search(me.search ? me.search : "", {
          hitsPerPage: 12,
        })
        .then(
          ({ hits }) => {
            me.records = hits;
            me.isBusy = false;
            me.isDisabled = false;
            me.verifyPaginate();
            me.updateFavorite();
          },
          () => {
            me.isBusy = false;
            me.isDisabled = false;
          }
        );
    },
    verifyPaginate() {
      const me = this;
      me.isLastPage = false;
      const index = me.searchClient.initIndex("alg_properties");
      index
        .search(me.search ? me.search : "", {
          hitsPerPage: me.count + 1,
          filters: me.filters ? me.filters : "",
        })
        .then(({ hits }) => {
          if (hits.length > me.records.length) {
            me.isLastPage = true;
          } else {
            me.isLastPage = false;
          }
        });
    },
    loadFarmsPage() {
      const me = this;
      me.isBusy2 = true;
      me.isDisabled = true;
      //2 por 12
      me.count = me.count + 12;
      const index = me.searchClient.initIndex(me.selected);
      index
        .search(me.search ? me.search : "", {
          hitsPerPage: me.count,
          filters: me.filters ? me.filters : "",
        })
        .then(
          ({ hits }) => {
            if (hits.length > 0) {
              me.records = [];
              me.records = hits;
            }
            me.isBusy2 = false;
            me.isDisabled = false;
            me.verifyPaginate();
            me.updateFavorite();
          },
          () => {
            me.isBusy2 = false;
            me.isDisabled = false;
          }
        );
    },
    sortBy() {
      const me = this;
      window.scroll(0, 0);
      me.isVisible = false;
      const index = me.searchClient.initIndex(me.selected);
      //2 por 12
      index
        .search(me.search ? me.search : "", {
          hitsPerPage: me.count,
          filters: me.filters ? me.filters : "",
        })
        .then(({ hits }) => {
          me.records = [];
          me.records = hits;
          me.verifyPaginate();
          me.updateFavorite();
        });
    },
    fillFilterSuccess(type) {
      const me = this;
      if (me.isKeyExists(me.$route.query, "buscar")) {
        if (type) me.search = me.$route.query.buscar.split("-")[0];
        if (me.$route.query.buscar.split("-")[1]) {
          me.searchCategoryId = me.$route.query.buscar.split("-")[1];
        }
      }

      if (me.isKeyExists(me.$route.query, "oportunity")) {
        me.filters = "oportunity = 1";
        me.loadFarmsOportunity();
      } else {
        me.loadFarms();
      }
    },
    isKeyExists(obj, key) {
      return Object.prototype.hasOwnProperty.call(obj, key);
    },
    loadFarmsOportunity() {
      const me = this;
      window.scroll(0, 0);
      me.isVisible = false;
      me.isDisabled = true;
      me.isBusy = true;
      me.records = [];
      const index = me.searchClient.initIndex("alg_properties");
      //2 por 12
      index
        .search("", {
          hitsPerPage: 12,
          filters: me.filters,
        })
        .then(
          ({ hits }) => {
            me.records = hits;
            me.isBusy = false;
            me.isDisabled = false;
            me.verifyPaginate();
            me.updateFavorite();
          },
          () => {
            me.isBusy = false;
            me.isDisabled = false;
          }
        );
    },
    async getRol() {
      let me = this;
      const dataAditional = await generateHash();
      try {
        let { data: data } = await me.provider.authRepository.getRol(
          dataAditional
        );
        me.userRol = data.user.role_user[0];
      } catch (error) {
        // console.log(error);
      }
    },
    deleteAllFilter() {
      const me = this;
      me.search = "";
      me.categoryId = null;
      me.loadFarms();
    },
    async loadFavorites() {
      const me = this;
      me.favorites = [];
      try {
        const dataAditional = await generateHash();
        const {
          data: { response },
        } = await me.favoriteRepository.getAll({
          ...dataAditional,
        });
        me.favorites = response;
      } catch (error) {
        me.favorites = [];
        // console.log(error);
      }
    },
    async updateFavorite() {
      const me = this;
      me.favorites = [];
      if (me.isAuthenticated) {
        await me.loadFavorites();
      }
      if (me.favorites.length > 0) {
        me.records.forEach((el) => {
          const farm = me.favorites.find(
            (x) => el.property_id == x.property_id
          );
          el.isFavorite = farm ? true : false;
        });
      } else {
        me.records.forEach((el) => {
          el.isFavorite = false;
        });
      }
    },
    updateCategoryId(id) {
      const me = this;
      me.categoryId = id;
    },
    async getListPropeties() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.publishFarmRepository.getAllNames(
          "PROPERTIES_LIST",
          dataAditional
        );

        if (data?.metadata) {
          me.meta = data?.metadata;
          me.loadDataMeta();
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    const me = this;
    me.search = "";
    me.isVisible = false;
    if (me.isKeyExists(to.query, "buscar")) {
      me.search = to.query.buscar.split("-")[0];
      if (to.query.buscar.split("-")[1]) {
        me.searchCategoryId = to.query.buscar.split("-")[1];
        me.categoryId = me.searchCategoryId;
      } else {
        me.searchCategoryId = null;
        me.categoryId = null;
        me.loadFarms();
      }
    } else if (me.isKeyExists(to.query, "oportunity")) {
      me.filters = "oportunity = 1";
      me.loadFarmsOportunity();
    }
    next();
  },
  async mounted() {
    const me = this;
    me.isVisible = me.$route.query.visible ? true : false;
    if (me.isAuthenticated) {
      me.getRol();
    }
    me.getListPropeties();
  },
};
</script>

<style lang="css">
.b {
  border: 1px solid #000;
}
.ais-InfiniteHits ol {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  place-content: center;
  place-items: center;
  gap: 0.5rem;
}

.sort-by {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  padding-right: 15px;
  padding-right: 60px;
}
@media (min-width: 768px) {
  .ais-InfiniteHits ol {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;
  }
}
@media (min-width: 1280px) {
  .ais-InfiniteHits ol {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2.25rem;
  }
}
</style>
